import {
  ComposerInfoJSONResponse,
  VTEXCollectionResponse,
  ComposerCommissionSalesSummary,
  ComposerCommissionSalesDetail,
  ComposerProductGroupSummary,
} from "myscore_models/lib/MyscoreResponseTypes.js";
import { PatchComposerInfoJSONPayload } from "myscore_models/lib/MyscoreRequestTypes.js";
import { AuthenticatedFetch } from "./Requests";
import { UploadResponse } from "./interfaces";

/**
 * Gets the profile of the currently logged in user.
 * @returns {Promise<ComposerInfoJSONResponse>}
 */
export async function getProfile(): Promise<ComposerInfoJSONResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/profile`
  );
  const resJson: { composer: ComposerInfoJSONResponse } = await res.json();
  return resJson.composer;
}

/**
 * Updates attributes of the profile of the currently logged in user.
 * @returns {Promise<ComposerInfoJSONResponse>}
 */
export async function updateProfile(
  payload: PatchComposerInfoJSONPayload
): Promise<ComposerInfoJSONResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/profile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  const resJson: { composer: ComposerInfoJSONResponse } = await res.json();
  return resJson.composer;
}

/**
 * Get products in a VTEX collection
 * @returns {Promise<VTEXCollectionResponse>}
 */
export async function getCollectionProducts(
  vtexCollectionId: number
): Promise<VTEXCollectionResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/collection/${vtexCollectionId}`
  );
  const resJson = await res.json();
  const collection: VTEXCollectionResponse = resJson.collection;
  return collection;
}

/**
 * Add products to a VTEX collection based on their pepper numbers (Product Group ID/ProductRefId)
 * @returns {Promise<VTEXCollectionResponse>}
 */
export async function addCollectionProducts(
  vtexCollectionId: number,
  pepperNumbers: string[]
): Promise<VTEXCollectionResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/collection/${vtexCollectionId}/addProducts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pepperNumbers }),
    }
  );
  const resJson = await res.json();
  const collection: VTEXCollectionResponse = resJson.collection;
  return collection;
}

/**
 * Removes products from a VTEX collection based on their pepper numbers (Product Group ID/ProductRefId)
 * @returns {Promise<VTEXCollectionResponse>}
 */
export async function removeCollectionProducts(
  vtexCollectionId: number,
  pepperNumbers: string[]
): Promise<VTEXCollectionResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/collection/${vtexCollectionId}/deleteProducts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pepperNumbers }),
    }
  );
  const resJson = await res.json();
  const collection: VTEXCollectionResponse = resJson.collection;
  return collection;
}

/**
 * Uploads the profile picture of the currently logged in composer.
 * @param file - The file to upload. Needs to be a valid image file in PNG, or JPEG format.
 */
export async function uploadProfilePicture(
  file: File
): Promise<ComposerInfoJSONResponse> {
  // Ensure file is an image (PNG or JPEG)
  if (!["image/png", "image/jpeg"].includes(file.type)) {
    throw new Error("Invalid file type. Please upload a PNG or JPEG image.");
  }

  const imageFormat = file.type === "image/png" ? "png" : "jpg";
  let res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/profile/photo/getUploadURL?imageFormat=${imageFormat}`
  );

  if (!res.ok) {
    throw new Error("Failed to get upload URL.");
  }

  const { uploadURL } = await res.json();

  // Upload the image to the CDN
  res = await fetch(uploadURL, {
    method: "PUT",
    body: file,
  });

  if (!res.ok) {
    throw new Error("Failed to upload image.");
  }

  // Set the image format in the profile
  res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/profile/photo/setImageFormat`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageFormat }),
    }
  );

  if (!res.ok) {
    throw new Error("Failed to set image format.");
  }

  const resJson: { composer: ComposerInfoJSONResponse } = await res.json();
  return resJson.composer;
}

/**
 * Get sales summary, split into POD and ePrint sales
 * @returns {Promise<ComposerCommissionSalesSummary>}
 */
export async function getSalesSummary(
  start: Date,
  end: Date
): Promise<ComposerCommissionSalesSummary> {
  const res = await AuthenticatedFetch(
    `${
      process.env.VUE_APP_MYSCORE_BACKEND
    }/api/v1/sales/summary?startDateTime=${encodeURIComponent(
      start.toISOString()
    )}&endDateTime=${encodeURIComponent(end.toISOString())}`
  );
  return await res.json();
}

/**
 * Get paginated sales for the sales table.
 * @returns {Promise<ComposerCommissionSalesDetail>}
 */
export async function getSalesDetail(
  start: Date,
  end: Date,
  offset?: number
): Promise<ComposerCommissionSalesDetail> {
  const res = await AuthenticatedFetch(
    `${
      process.env.VUE_APP_MYSCORE_BACKEND
    }/api/v1/sales/detail?startDateTime=${encodeURIComponent(
      start.toISOString()
    )}&endDateTime=${encodeURIComponent(end.toISOString())}${
      offset ? `&offset=${offset}` : ""
    }`
  );
  return await res.json();
}

/**
 * Get paginated product groups for a composer, used for Catalog page.
 * To get featured products, first get the featured collection ID from the composer's profile,
 * then use that ID to get the products in that collection from this function.
 * @returns {Promise<ComposerProductGroupSummary>}
 */
export async function getProductGroups(
  sortBy?: "title" | "createdDate",
  sortDirection?: "asc" | "desc",
  search?: string,
  vtexCollectionId?: number,
  offset?: number,
  limit?: number,
  featuredOnly?: boolean
): Promise<ComposerProductGroupSummary> {
  const query = new URLSearchParams();
  if (sortBy) query.append("sortBy", sortBy);
  if (sortDirection) query.append("sortDirection", sortDirection);
  if (search) query.append("search", search);
  if (vtexCollectionId)
    query.append("vtexCollectionId", vtexCollectionId.toString());
  if (offset !== undefined) query.append("offset", offset.toString());
  if (limit) query.append("limit", limit.toString());
  if (featuredOnly) query.append("featuredOnly", featuredOnly.toString());
  const res = await AuthenticatedFetch(
    `${
      process.env.VUE_APP_MYSCORE_BACKEND
    }/api/v1/productGroups?${query.toString()}`
  );
  return await res.json();
}

export async function getUploadDrafts(): Promise<UploadResponse> {
  const res = await AuthenticatedFetch(
    `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/composer/uploadsInProgress`
  );
  return await res.json();
}

export async function deleteUploadDraft(uploadId: string) {
  try {
    const res = await AuthenticatedFetch(
      `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/upload/${uploadId}`,
      {
        method: "DELETE",
      }
    );
    return await res.json();
  } catch (error) {
    console.log("Error at deleteUploadDraft: ", error);
    throw error;
  }
}

export async function sendSupportEmail(email: {
  subject: string;
  message: string;
}): Promise<{ status: number } | undefined> {
  try {
    const res = await AuthenticatedFetch(
      `${process.env.VUE_APP_MYSCORE_BACKEND}/api/v1/composer/sendSupportEmail`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(email),
      }
    );
    return { status: res.status };
  } catch (error) {
    console.log("Error at sendSupportEmail: ", error);
  }
}

export async function downloadSales(start: Date, end: Date, offset = 0) {
  let res: Response | undefined;
  try {
    res = await AuthenticatedFetch(
      `${
        process.env.VUE_APP_MYSCORE_BACKEND
      }/api/v1/sales/detail/download?startDateTime=${start.toISOString()}&endDateTime=${end.toISOString()}${`&offset=${offset}`}`
    );
    return res;
  } catch (error) {
    return res;
  }
}
