var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"nav",attrs:{"app":"","color":"#152F3D","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/profile"}},[_c('img',{attrs:{"src":require("@/assets/myscorelogo.png"),"alt":"Logo","id":"logo"}})])],1),_c('v-spacer'),_c('div',{staticClass:"desktop-menu"},[_vm._l((_vm.mainLinks),function(item,index){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBetaUser),expression:"isBetaUser"}],key:index.toLocaleString(),attrs:{"to":item.link}},[_c('v-btn',{class:_vm.$route.path.startsWith(item.link)
            ? 'router-link-exact-active nav-item'
            : 'nav-item',attrs:{"plain":"","disabled":!_vm.allowed,"ripple":false}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"nav-item",class:_vm.$route.path.startsWith('/upload')
              ? 'router-link-exact-active'
              : '',attrs:{"plain":"","disabled":!_vm.allowed,"ripple":false}},on),[_vm._v(" Upload ")])]}}])},[_c('v-list',{staticClass:"nav-list"},_vm._l((_vm.uploadLinks),function(item,index){return _c('v-list-item',{key:index.toLocaleString()},[_c('v-btn',{staticClass:"nav-item",attrs:{"plain":"","ripple":false,"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"plain":"","icon":"","medium":"","ripple":false}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"nav-list"},[_c('v-list-item',[_c('v-btn',{staticClass:"nav-item",attrs:{"href":_vm.helpLink.link,"target":"_blank","text":""}},[_vm._v(" "+_vm._s(_vm.helpLink.title)+" ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"nav-item",attrs:{"text":""},on:{"click":_vm.logoutUser}},[_vm._v(" Logout ")])],1)],1)],1)],2),_c('div',{staticClass:"mobile-menu"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","medium":"","plain":"","ripple":false}},on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',{staticClass:"nav-list"},[_vm._l((_vm.mainLinks),function(item,index){return _c('v-list-item',{key:index.toLocaleString()},[_c('v-btn',{staticClass:"nav-item",attrs:{"plain":"","to":item.link,"ripple":false,"disabled":!_vm.allowed}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('v-divider',{staticClass:"link-divider"}),_c('v-list-item',[_c('v-btn',{staticClass:"nav-item",attrs:{"href":_vm.helpLink.link,"dark":"","target":"_blank","rounded":"","width":150,"elevation":"0"}},[_vm._v(" "+_vm._s(_vm.helpLink.title)+" ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"nav-item",attrs:{"outlined":"","rounded":"","width":"100%"},on:{"click":_vm.logoutUser}},[_vm._v(" Logout ")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }